<template>
  <div
    v-show="!isEmpty"
    :class="{ 'text-center': center }"
    class="m-alerts"
  >
    <v-card
      v-for="(item, i) in list"
      :key="'alert-'+i"
      text
      :data-type="item.type"
      :data-view="item.view"
      :outlined="item.outlined"
      :class="[item.color.background, list.length==1 ? 'mb-0' : 'mb-2']"
      class="alert rounded-lg pa-5 pt-4"
      @click="open(item)"
    >
      <v-progress-linear
        :active="item.loading"
        absolute
        top
        indeterminate
        height="3"
        color="grey darken-1"
      />
      <p 
        v-if="!headless"
        :class="[item.color.title]"
        class="d-flex align-center mb-3"
      >
        <span 
          class="text-overline d-inline-block"
        >
          {{ item.title }}
        </span>
        <v-spacer />
        <v-icon 
          size="20"
          :class="[item.color.title]"
          class="icon mt-n1"
        >
          {{ item.icon }}
        </v-icon>
      </p>
      <!-- <div 
        v-if="item.type=='benefits'"
        class="benefits-container"
        :class="{ 'py-4': !item.data.full }"
      >
        <img
          v-if="!center"
          src="/img/confetti.svg"
          class="confetti"
        />
        <m-benefits
          :data="item.data.content"
          :url="item.data.url"
          :full="item.data.full"
          :dark="!item.data.full"
          :teaser="true"
          class="mx-n4"
          :style="{ width: maxWidth }"
        />
      </div> -->
      <div 
        :class="[item.color.text]"
        class="alert-content"
      >
        <v-skeleton-loader
          v-if="item.value===null"
          tile
          max-width="80%"
          type="sentences"
          class="mt-2"
        />
        <span
          v-else
          class="text text-body-2 mb-1"
          v-html="item.text"
        />
       <v-expand-transition>
          <v-alert
            v-if="item.type==='calendar' && item.absences > 0"
            color="warning"
            text
            class="schedule-alert d-flex px-3 mt-3 mb-1 py-3"
            :icon="icons.exclamation"
          >
            <p class="alert-text white--text text-caption mb-0">
              <b>{{ item.absences | absenceText }}</b>
            </p>
            
          </v-alert>
        </v-expand-transition>

      </div>
      <!-- <v-btn
        v-if="item.type=='calendar'"
        fab
        absolute
        top
        right
        light
        small
        class="mt-8 mr-n1 primary--text text--darken-1"
        @click.stop="open('map')"
      >
        <v-icon>
          {{ icons.directions }}
        </v-icon>
      </v-btn> -->
    </v-card>
  </div>
</template>

<style>

  .m-alerts .schedule-alert .alert-text {
    opacity: 1;
    font-size: 14px;
  }

  .m-alerts .alert .icon {
    opacity: .6;
  }

  .m-alerts .alert .alert-content {
    position: relative;
  }
  .m-alerts .alert .benefits-container .confetti {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: .25;
  }
  /* .m-alerts .alert .alert-content .text {
    color: white;
    display: inline-block;
    opacity: .8;
  } */
  .m-alerts .alert .alert-content .text b {
    white-space: nowrap;
  }

</style>

<script>
  import services from '@/services'

  import { 
    mdiAlertOutline,
    mdiShieldAlertOutline,
    mdiShieldCheckOutline,
    mdiCalendarOutline,
    mdiMessageBadgeOutline,
    mdiPoll,
    mdiGiftOutline,
    mdiAccountMultiplePlus,
    mdiMapMarker,
    mdiSale,
    mdiAlertCircleOutline,
    mdiStarOutline,
    mdiInformationOutline
  } from '@mdi/js';

  export default {
    props: {
      data: {
        type: Array,
        default: () => []
      },
      hive: {
        type: Object,
        default: () => null
      },
      maxWidth: {
        type: String,
        default: '100vw'
      },
      headless: {
        type: Boolean,
        default: false
      },
      center: {
        type: Boolean,
        default: false
      },
    },

    data: () => ({
      icons: {
        urgent: mdiAlertOutline,
        review: {
          ok: mdiShieldCheckOutline,
          alert: mdiShieldAlertOutline
        },
        // stat: {
        //   payments: mdiPoll,
        //   bonus: mdiGiftOutline,
        //   referrals: mdiAccountMultiplePlus,
        // },
        calendar: mdiCalendarOutline,
        exclamation: mdiAlertCircleOutline, 
        info: mdiInformationOutline, 
        message: mdiMessageBadgeOutline,
        directions: mdiMapMarker,
        benefits: mdiSale,
        event: mdiStarOutline
      },
      colors: {
        info: {
          title: 'secondary--text text--lighten-1',
          text: 'white--text',
          background: 'secondary darken-1'
        },
        warning: {
          title: 'error--text text--darken-4',
          text: 'grey--text text--darken-4',
          background: 'warning'
        }
      },
      controller: {
        benefits: {
          loaded: []
        }
      }
    }),

    computed: {
      list () {
        let list = _.reject(this.data, ['hidden', true]);
        list = _.map(list, item => {
          if (!_.has(item, 'color')||!item.color) {
            item.color = this.colors[item.critical===true ? 'warning' : 'info'];
          }
          if (item.type=='review') {
            item.icon = _.get(this.icons, 'review.'+ (item.required ? 'alert' : 'ok'));
          }else{
            item.icon = this.icons[item.type];
          }
          const dynamic = /\{\{(.*?)\}\}/g;
          _.each([...item.text.matchAll(dynamic)], (match) => {
            item.text = item.text.replace(match[0], item[match[1]]);
          });
          item.text = item.text.replace(/\*([^\*]+)\*/g,'<b>$1</b>').replace(/\n/g, '<br>');
          if (_.has(item, 'cards')) {
            item.cards = _.map(item.cards, card => {
              card.text = card.text.replace(/\*([^\*]+)\*/g,'<b>$1</b>').replace(/\n/g, '<br>');
              return card;
            })
          }
          if (!_.has(item, 'loading')) item.loading = false;
          // console.log(item.text)
          return item;
        })
        return _.orderBy(list, ['priority'], ['desc']);
      },

      benefitHeight () {
        const list = this.data;
        const loaded = this.controller.benefits.loaded;
        const first = _.has(this.$refs, 'benefit-0') && _.indexOf(loaded, 0)>=0 ? this.$refs['benefit-0'][0].$el : null;
        const height = first==null ? 'auto' : first.clientHeight+'px';
        // console.log('height', height);
        return height;
      },
      isEmpty () {
        const data = this.data;
        return _.isEmpty(data);
      }
    },

    watch: {
      // alerts: {
      //   immediate: true,
      //   deep: true,
      //   handler (v) {
      //     if (v.length>0) setTimeout(($) => {
      //       $.window = 1;
      //     }, 2000, this);
      //   }
      // }
    },

    methods: {
      ...services,
      
      open (alert) {
        // console.log(alert);
        setTimeout(($,alert) => {
          if (alert=='map') {
            const hive = this.hive;
            window.open(hive.uri);
          }else if (_.has(alert, 'click')) {
            alert.click(this);
          }else if (_.has(alert, 'open')) {
            this.$emit('open', alert.open);
          }else if (alert.type=='message'||(alert.type=='calendar'&&_.has(alert, 'ticket')&&alert.ticket!=null)) {
            this.$emit('open', { view: 'help', ticket: alert.ticket });
          }else if (alert.type=='stat') {
            this.$emit('open', alert.view);
          }else if (alert.type=='event') {
            this.$emit('open', { view: 'help', section: 'events', event: alert.event });
          }else if (!(alert.type=='review'&&!alert.required)) {
            this.$emit('open', { view: 'help', section: alert.issue });
          }
        }, 250, this, alert);
      },

      // toggleStatus (b) {
      //   this.$emit('toggle-status', b);
      // },

      // toggleHelp (b, context, section, item) {
      //   this.$emit('toggle-help', b, context, section, item);
      // },

      benefitLoaded (i) {
        this.controller.benefits.loaded.push(i);
      },

      // onIntersect (entries, observer) {
      //   const type = entries[0].target.dataset.type;
      //   const view = entries[0].target.dataset.view;
      //   // console.log(type, view, entries[0].isIntersecting);
      //   if (type=='stat'&&entries[0].isIntersecting) {
      //     this.$emit('get-stats', view);
      //   }
      // },
    },
    filters: {
      absenceText(count) {
        const text = count > 1 ? 'faltas ou remarcações' : 'falta ou remarcação'
        return `Atenção: Você possui ${count} ${text} nos últimos 90 dias`
      }
    },
    // components: {
    //   MBenefits: () => import('@/views/help/Benefits'),
    // }
  }
</script>